import { createIcon } from '@/components/atoms/Icon';

export default createIcon({
  title: 'Location',
  displayName: 'LocationIcon',
  viewBox: '0 0 20 20',
  path: {
    outline:
      'M5.536 2.927c-1.128.985-1.988 2.74-1.988 5.911 0 4.3 3.355 7.604 5.447 9.232.604.47 1.405.47 2.01 0 2.092-1.628 5.447-4.932 5.447-9.232 0-3.17-.86-4.926-1.988-5.91-1.15-1.006-2.736-1.35-4.464-1.35-1.728 0-3.313.344-4.464 1.35ZM4.528 1.73C6.088.367 8.115 0 10 0c1.885 0 3.913.367 5.472 1.729C17.054 3.11 18 5.38 18 8.839c0 5.107-3.923 8.827-6.056 10.485-1.159.901-2.729.901-3.888 0C5.923 17.666 2 13.946 2 8.838c0-3.458.947-5.727 2.528-7.11ZM10 5.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM6.25 8a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0Z',
    filled:
      'M2.002 8.633c0-7.11 4-8.633 8-8.633s8 1.523 8 8.633c0 5.583-4.934 9.6-7.052 11.067-.578.4-1.318.4-1.896 0-2.118-1.467-7.052-5.484-7.052-11.067Zm8 2.539c1.657 0 3-1.365 3-3.047 0-1.683-1.343-3.047-3-3.047s-3 1.364-3 3.047c0 1.682 1.343 3.046 3 3.046Z',
  },
});
